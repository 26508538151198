@import 'abstracts/variables';
@import 'ProductCard.module';
@import 'abstracts/helpers';

@gradientFadeZIndex: @z-index-color-palette + 1; // z-index for gradient fade, above product card color palette z-index

.carousel {
  margin: 0 -@spacer;
  position: relative;

  .scroll-container {
    -ms-overflow-style: none; /* hide scrollbar Internet Explorer 10+ */
    scrollbar-width: none; /* hide scrollbar Firefox */
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: @spacer 0; // Add padding to account for hover effect overflowing container
    margin: -@spacer 0; // Add negative margin to restore original position after adding padding

    > a,
    > a:hover,
    > a:active,
    > a:focus {
      margin-left: @spacer * 2;
      text-decoration: none;
      color: inherit;
    }

    > a:last-of-type {
      margin-right: @spacer;
    }

    &::-webkit-scrollbar {
      /* hide scrollbar */
      display: none;
    }
  }

  .scroll-icon {
    position: absolute;
    padding: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    background: transparent;
    overflow: visible;
    z-index: @gradientFadeZIndex + 1;

    &.hidden {
      display: none;
    }

    &:hover,
    &:focus {
      background: transparent;
    }

    svg {
      transition: transform @transition-delay ease-out;

      &:hover {
        circle {
          stroke: @primary-dark;
        }

        path {
          fill: @primary-dark;
        }
      }
    }

    &.right-icon {
      right: @spacer;

      svg:hover {
        transform: translate3d(-4px, 0, 0);
      }
    }

    &.left-icon {
      left: 10px;

      svg:hover {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  // Mixin for gradient elements
  .gradient-element {
    position: absolute;
    width: 110px; // magic number for gradient
    background: @fade-gradient;
    z-index: @gradientFadeZIndex;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    @media @below-md {
      width: 85px !important; // magic number for smaller size gradient
      background: @fade-gradient;
    }
  }

  .gradient-element-left {
    .gradient-element;

    left: -2px; // Fill tiny scroll gap
    transform: rotate(-180deg);
  }

  .gradient-element-right {
    .gradient-element;

    right: -2px; // Fill tiny scroll gap
  }

  :global {
    .rs-stack {
      :global(.rs-stack-item) {
        max-width: 100%;
      }
    }
  }
}
