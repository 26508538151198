@import 'abstracts/variables';

@card-dimension: 250px;
@grid-gap: @spacer * 2;

.product-grid {
  --product-grid-card-width: unit(@card-dimension);
  --product-grid-gap: unit(@grid-gap);
  // Needs to be added as css variable to be accessible in javascript
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(@card-dimension, 1fr));
  grid-gap: @grid-gap;
  grid-auto-flow: row dense;
  margin-top: @spacer * 2;

  @media @below-sm {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: @spacer * 4;
  }
}

.skeleton-loading-card {
  max-width: 100% !important; // Override the inline styles applied on the Placeholder.Graph rsuite component
  height: 360px !important; // Magic number to fix the card height to match the card design
  border-radius: @border-radius;
}
