@import 'abstracts/variables';
@import 'abstracts/helpers';
@import 'ProductsList.module';

@border-gray: @B200; // same as @panel-border in rsuite
@z-index-color-palette: 5;

.product-card {
  .desktop-panel-border {
    border-radius: @border-radius;

    .anchor-link {
      border: 1px solid @B200;
      border-bottom: none;
      border-top-left-radius: @border-radius;
      border-top-right-radius: @border-radius;
      height: 105%; // To stretch items veritcally when MOV is not provided for some cards
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :global(.rs-panel) {
    // We can disable rule as .product-card and .banner-* are mutually exclusive
    transition:
      transform @transition-delay ease-out,
      box-shadow @transition-delay ease-out;
    cursor: pointer;
    position: relative;
    .full-h-w;

    &:hover {
      box-shadow: 0 2px 10px 0 rgba(@B600, 0.3);
      transform: translate3d(0, -2px, 0);
    }

    :global(.rs-panel-body) {
      display: grid;
      align-content: stretch;
      height: 100%;
      background: #fff;

      .top-container {
        align-items: flex-start;
        align-content: flex-start;
        position: relative;
        border: none;
      }

      .bottom-container {
        display: grid;
        align-content: flex-end;
        align-items: flex-end;
        border: none;
        border-top: 1px solid @border-gray;
      }
    }
  }

  >a,
  >a:hover,
  >a:active,
  >a:focus {
    text-decoration: none;
    color: inherit;
    .full-h-w;
  }

  .figure {
    height: 100%;
  }

  img {
    object-fit: contain;

    &::before {
      // Prettier breaking of images
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }

  .image-container-height {
    // This defines the max-height of the actual image <Image />
    height: @card-dimension;
    max-height: 200px;
    margin: 0 auto; // Center horisontally

    @media @below-md {
      max-height: 160px; // to match figma
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: @spacer @spacer*2;
    max-width: 100%;
    border-bottom: 0;

    .product-discounts {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      max-width: 205px;
      gap: 8px;

      b {
        min-width: max-content;
      }

      .strike-through {
        font-size: @font-size-small;
        text-decoration: line-through;
        color: @red;
      }

      .first-letter-tag {
        padding-right: 0.5px;
        padding-bottom: 1px;
      }
    }

    .name {
      white-space: pre-wrap;
      hyphens: auto;
      // Added 9px to exactly match figma
      margin-top: 9px;
    }

    p.product-minimum {
      margin-top: 3px; // Visual fix
      // Fix to allow minimum text to truncate without expanding in width
      min-width: 100%;
      width: 0;
    }
  }

  .supplier-container {
    padding: @spacer @spacer @spacer @spacer * 2;
    border-top: 1px solid @border-gray;
    border-radius: 0;
    color: @B800;
    height: 65px;
  }

  .buttons-container {
    display: flex;
    border: 1px solid @border-gray;
    width: 100%;
    border-bottom-left-radius: @border-radius;
    border-bottom-right-radius: 5px;
    height: 45px;
    align-self: self-end;

    >button {
      border: none;
      width: 50%;
      border-radius: 0;
      padding: 12px 0;
      height: 100%;

      @media @below-md {
        width: 100%;
      }
    }

    >a {
      >button {
        border: none;
        width: 100%;
        border-radius: 0;
        padding: 12px 0;
        height: 100%;
      }
    }

    .save-product-button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    .view-product-anchor-link {
      flex: 2.5;
      border-bottom-right-radius: @border-radius;

      .view-product-button-right-radius {
        border-bottom-right-radius: @border-radius;
      }

      .view-product-button-left-radius {
        border-bottom-left-radius: @border-radius;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :global(.rs-panel-body),
  .buttons-container {
    >a,
    >a:hover,
    >a:active,
    >a:focus {
      text-decoration: none;
      color: inherit;
      .full-h-w;
    }
  }

  .heart-icon {
    height: 20px;
    width: 22px;
  }
}

.indicator-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 25px;
  z-index: @z-index-color-palette;

  &.product-card-mobile {
    @media @below-md {
      bottom: unset;
      top: 50%;
      transform: translate(0, -50%); // Center container vertically
    }
  }

  .indicator-colors-container {
    display: grid;
    align-items: center;
  }

  .color-indicator-circle {
    border-radius: 50%;
    display: block;
    border: 2px solid white;
    width: 25px;
    height: 25px;
    grid-row: 1/1;
  }

  .indicator-sizes-container {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    font-size: @font-size-small;
    font-weight: 500;
    color: @B600;

    .size-tag {
      padding: @spacer * 0.5;
      border: 1px solid @B200;
      border-radius: @border-radius;
      background: white;
    }
  }

  .extra-color-number {
    font-size: @font-size-base - 2;
    font-weight: 400;
    color: @B600;
  }

  .white-circle {
    border-color: @B200;
  }
}
